#landing-page h2 {
  color: var(--color-primary);
}

#landing-page p {
  margin-bottom: 20px;
  margin-top: 10px;
  font-size: 1.1rem;
}

.intake-option {
  margin: auto;
  padding-top: 10%;
  max-width: 500px;
  width: 90%;
}

.intake-option h4 {
  font-size: 1.25rem;
  font-weight: 300;
  color: var(--color-primary-main);
}
