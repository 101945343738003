.thank-you {
  max-width: 700px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.thank-you h1 {
  color: var(--color-primary-main);
}

.thank-you h5 {
  color: var(--color-primary-main);
  font-size: 1.5rem;
  text-align: center;
}

.thank-you p {
  margin-top: 40px;
  color: var(--color-primary-main);
}

.thank-you a {
  display: inline-block;
  color: var(--color-secondary-main);
  margin-top: 15px;
  font-size: 1rem;
}

.thank-you a:hover {
  text-decoration: underline;
}