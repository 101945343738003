/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

:root {
  --color-navy: #033157;
  --color-orange: #fe9162;
  --color-burntOrange: #ca5116;
  --color-brightYellow: #ffd369;
  --color-mauve: #bb8794;
  --color-lightTeal: #a8c5c9;
  --color-transparentTeal: #a8c5c942;
  --color-darkGray: #444;
  --color-gray: #70757a;
  --color-accent: #f50057;
  --color-lightGray: #b7b7b7;
  --color-backgroundGrayTransparent: #ededed5c;
  --color-backgroundGray: #ededed;
  --color-background: #fdf3ea;
  --color-peach: #F0B49E;
  --color-primary: #322030;
  --color-secondary: #ca5116;
  --color-secondary-dark: #b7370c;
  --color-info: #abb4c5;
  --color-info-light: #abb4c545;
  --color-error: #ef5350;
}

:root {
  --color-primary-dark: #322030;
  --color-primary-main: #52384f;
  --color-primary-light: #C2BCC1;
  --color-primary-hover: #1f131e0a;
  --color-secondary-dark: #B7370C;
  --color-secondary-main: #CA5116;
  --color-secondary-light: #EFCBB9;
  --color-info-dark: #919CB0;
  --color-info-main: #ABB4C5;
  --color-info-light: #E6E9EE;
  --color-error-dark: #8A001D;
  --color-error-main: #A74056;
  --color-error-light: #E2BFC7;
  --color-highlight-dark: #FFD897;
  --color-highlight-main: #FFE3AF;
  --color-highlight-light: #FFF2DC;
  --color-approved-dark: #9DA89E;
  --color-approved-main: #CBD9CC;
  --color-approved-light: #F2F5F2;
  --color-grey-1: #1A1A1A;
  --color-grey-2: #808080;
  --color-grey-3: #BFBFBF;
  --color-grey-4: #DFDFDF;
  --color-background-primary: #FAF8F6;
  --color-background-secondary: #FAFAFA;
}

div {
  box-sizing: border-box;
}

body, p { 
  font-size: 15px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', serif;
  color: var(--color-grey-1);
  margin-bottom: 20px;
  line-height: 1.2em;
}

h1 {
  font-size: 3.5rem;
  font-weight: 900;
}

h2 {
  font-size: 3rem;
  font-weight: 900;
}

h3 {
  font-size: 2rem;
  font-weight: 700;
  color: var(--color-primary-main);
}

h4 {
  font-size: 1.5rem;
  font-weight: 700;
}

h5 {
  font-size: 1.125rem;
  font-weight: 700;
}

h6 {
  font-size: 1.05rem;
  font-weight: 500;
}

p, a, li {
  font-size: 1rem;
  line-height: 1.4em;
}

a {
  text-decoration: none;
  font-size: 0.9rem;
  letter-spacing: .01em;
}

a:hover {
  color: var(--color-darkGray);
}

/* label {
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 5px;
  display: block;
  color: var(--color-grey-1);
} */

.margin-top-20 {
  margin-top: 20px;
}

/* button border */
.button-outline {
  display: inline-block;
  cursor: pointer;
  background: transparent;
  border: 0;
  position: relative;
  z-index: 0;
  padding: 0.8rem 1.5625rem;
  -webkit-transform: translate3d(.375rem,-.375rem,0);
  transform: translate3d(.375rem,-.375rem,0);
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
  color: black;
  height: auto;
  margin-bottom: .375rem;
  margin-top: 30px;
  text-align: center;
  text-decoration: none;
  outline: none;
  min-width: 9rem;
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
  box-sizing: border-box;
  font-family: 'playfair display', serif;
}

.button-outline:hover,
.button-outline:hover::before {
  color: black;
  transform: translateZ(0);
}

.button-outline:before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: var(--color-orange);
  content: "";
  -webkit-transform: translate3d(-.375rem,.375rem,0);
  transform: translate3d(-.375rem,.375rem,0);
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

.button-outline:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid;
  content: "";
  -webkit-transition: all .3s ease-in;
  transition: all .3s ease-in;
}

.page-outter {
  background-color: var(--color-backgroundGrayTransparent);
  min-height: 100vh;
}

.page {
  padding: 20px;
  background-color: var(--color-backgroundGrayTransparent);
}

.panel-body {
  padding: 20px;
}

.flex-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.alert-box {
  padding: 10px;
  color: var(--color-darkGray);
  border: 2px solid var(--color-secondary-dark);
  font-size: 1.1rem;
  line-height: 1.2em;
  margin-top: 20px;
}

.MuiFormControl-root {
  margin-top: 20px !important;
  width: 100%;
}

.MuiOutlinedInput-root {
  border-radius: 0 !important;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100% !important;
  margin-top: 20px;
}

.MuiFormLabel-root.Mui-focused {
  color: var(--color-navy) !important;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl.Mui-focused fieldset{
  border-color: var(--color-navy)!important;
}

@media only screen and (min-width: 650px) {
  .panel-body {
    padding: 40px;
    border: 0.5px solid var(--color-lightGray);
    max-width: 550px;
    margin: 30px auto;
  }

  body {
    background-color: var(--color-backgroundGrayTransparent);
  }
}

.user-option {
  margin: 0 auto;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.user-option-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.button-outline.mauve::before {
  background-color: #D99B9B;
}

.button-outline.teal::before {
  background-color: var(--color-lightTeal);
}

.button-outline.lightGreen::before {
  background-color: #CACCB6;
}

.button-outline.purple::before {
  background-color: #AAA1AF;
}

.button-outline.peach::before {
  background-color: var(--color-peach);
}

.checkbox-wrapper {
  margin-top: 20px;
  width: 100%;
}

.checkbox-set {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.checkbox-set-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.loading-overlay {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.7);
  z-index: 100;
}

@media only screen and (min-width: 400px) {
  .checkbox-set-wrapper {
    flex-wrap: nowrap;
  }
}

.compliance-check {
  margin-top: 30px;
}

.compliance-check .MuiFormLabel-root {
  line-height: 1.4;
}